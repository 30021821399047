// import { ResizeObserver as Polyfill } from '@juggle/resize-observer';


/*__VARIABLES__________________________*/

const getVar = function (){

}

const setVars = function(variablesObj){
	for (const property in variablesObj) {
		document.documentElement.style.setProperty(property, variablesObj[property])
	}
}

const setMobileVariables = function (){
	// console.log('setMobileVariables')
	let variablesObj = {

		'--home-esp-small': '3vw',
		'--home-esp-med': '3vw',
		'--home-esp-big': '3vw',

		'--home-border-size': '',

		'--home-font-title': '115px',
		'--home-font-description': '20px',

		'--info-font-adress': '5vw',
		'--info-font-description': '30px',
		'--info-font-credits': '20px',

		'--slide-width' : '100vw',

	}
	setVars(variablesObj)
}

const setTabletVariables = function (){
	// console.log('setTabletVariables')
	let variablesObj = {
		'--home-esp-small': '0.5vw',
		'--home-esp-med': '1vw',
		'--home-esp-big': '1.5vw',

		'--home-border-size': '',

		'--home-font-title': '12vw',
		'--home-font-description': '20px',

		'--info-font-adress': '1.4vw',
		'--info-font-description': '2vw',
		'--info-font-credits': '1.4vw',

		'--slide-width' : '50vw',
	}
	setVars(variablesObj)
 }

// const setVariables = function (){
// 	console.log('setVariables')
// 	let variablesObj = {

// 	}
// 	setVars(variablesObj)
// }


const setDesktopLandscapeSmall = function (){

	// console.log('setDesktopLandscapeSmall')
	let variablesObj = {
		'--home-esp-small': '0.5vw',
		'--home-esp-med': '1vw',
		'--home-esp-big': '1.5vw',

		'--home-border-size': '',

		'--home-font-title': '12vw',
		'--home-font-description': '20px',

		'--info-font-adress': '20px',
		'--info-font-description': '24px',
		'--info-font-credits': '16px',

		'--slide-width' : '50vw',
	}
	setVars(variablesObj)
}

const setDesktopLandscapeMed = function (){

	// console.log('setDesktopLandscapeMed')
	let variablesObj = {
		'--home-esp-small': '0.5vw',
		'--home-esp-med': '1vw',
		'--home-esp-big': '1.5vw',

		'--home-border-size': '',

		'--home-font-title': '12vw',
		'--home-font-description': '20px',

		'--info-font-adress': '24px',
		'--info-font-description': '28px',
		'--info-font-credits': '18px',

		'--slide-width' : '50vw',
	}
	setVars(variablesObj)
}

const setDesktopLandscapeBig = function (){

	// console.log('setDesktopLandscapeBig')
	// console.log(window.innerWidth)

	let variablesObj = {
		'--home-esp-small': '0.5vw',
		'--home-esp-med': '0.7vw',
		'--home-esp-big': '1vw',

		'--home-border-size': '',

		'--home-font-title': '12vw',
		'--home-font-description': '22px',

		'--info-font-adress': '24px',
		'--info-font-description': '32px',
		'--info-font-credits': '20px',

		'--slide-width' : '50vw',
	}
	setVars(variablesObj)
}


const setDesktopLandscapeMax = function (){

	// console.log('setDesktopLandscapeMax')

	let variablesObj = {
		'--home-esp-small': '0.5vw',
		'--home-esp-med': '0.7vw',
		'--home-esp-big': '1vw',

		'--home-border-size': '',

		'--home-font-title': '12vw',
		'--home-font-description': '24px',

		'--info-font-adress': '26px',
		'--info-font-description': '36px',
		'--info-font-credits': '22px',

		'--slide-width' : '50vw',
	}
	setVars(variablesObj)

}

/* _________ _________ _________ _________ _________ _________ _________ _________*/

function getRandom(max){
	return Math.floor(Math.random() * Math.floor(max));
}

export default {
	init: () => {
		return { 
			getVar, 
			// setVariables, 
			setTabletVariables, setMobileVariables,
			setDesktopLandscapeSmall, setDesktopLandscapeMed, setDesktopLandscapeBig, setDesktopLandscapeMax,
			getRandom
		}
	}
}






