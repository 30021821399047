class Datastore {

	constructor () {

		this.state = {}
	}

	pageIsLoaded(){

		if (Object.keys(this.state).length === 0){
			return false
		}else {
			return true
		}	
	}

	getPage(){	
		return this.state;
	}

	setPage(content){
		this.state = content;
	}

}


export default {
	init: () => {

		return new Datastore()
	}
}


