
//getSite -> env
//
//getPage -> content site

let apiUrl

const getSite = async () => {
	
  let pageUrl = apiUrl + '/home.json'  
  // let pageUrl = apiUrl + apiLang + '/env.json'

  const resp = await fetch(pageUrl)
  const home = await resp.json()

  return home
}

export default {
	init: (url) => {

		apiUrl = url

		return {getSite, apiUrl}
	}
}








