import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

export default {
  init: (datastore, api, client) => {
    datastore, api, client

    const routes = [
      {
        path: '/',
        name: 'Home',
        // component: Home
        components: {
          default: () => import( '../views/Home.vue'),
          mobile: () => import('../views/HomeMobile.vue')
        }
      },
    ]

    const router = new VueRouter({
       mode: 'history',
       routes
    })

    return router
  }
}