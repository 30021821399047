export default {

	data: function(){
		return {
			stateClient: this.$client.state,
		}
	},

	computed: {
		ClientWidth: function(){ return this.stateClient.scale },
		ClientFormat: function(){ return this.stateClient.format},
		ClientDevice: function(){ return this.stateClient.device },

    whatComputConfig: function(){

    if (this.ClientFormat == 'portrait'){

      if (this.ClientWidth == 'mobileWidth'){
        return '-mobile'
      }else {
        return '-portrait'
      }
      
    }else {
      return ''
    }

    },
	}
	
}